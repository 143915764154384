<template>
  <v-row>
    <!-- basic -->
    <v-col cols="12">
      <v-card>
        <v-card-title>Clientes Asignados a  <strong class="ml-2"> {{ employeeName }} </strong></v-card-title>
        <DataTableClient rol="Administrador"></DataTableClient>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import DataTableClient from '../client/datatables/DataTableClient.vue'

export default {
  components: {
    DataTableClient,

  },
  setup() {
    return {
      employeeName: null,
    }
  },
  mounted() {
    this.getEmployeebyID(this.$route.params.id)
  },
  methods: {
    async getEmployeebyID(personId) {
      this.url = `${this.$store.getters.urlBase}/api/Employee/employeeById`

      const options = {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
        params: { idPerson: personId },
        url: this.url,
      }
      const respuesta = await axios(this.url, options)

      if (respuesta.status === 200) {
        this.employeeName = respuesta.data.surname + ' ' + respuesta.data.name
      } else {
        // this.colorSnack = "red";
        // this.mensajeSnack = respuesta.data;
        // this.Showsnackbar = true;

      }
    },
  },
}
</script>
